define("discourse/plugins/gorisa-youtubes/discourse/templates/youtubes", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="youtubes">
    <span>
      <PluginOutlet
        @name="before-youtubes-search"
        @connectorTagName="div"
        @outletArgs={{hash
          selectCategory=(action "updateSelectedCategories")
          selectTag=(action "updateSelectedTags")
          tags=this.indexController.tags
          categories=this.indexController.categories
        }}
      />
    </span>
  
    <YoutubesSearch
      @searchTerm={{readonly this.indexController.searchTerm}}
      @onSearch={{action "performSearch"}}
    />
  
    {{outlet}}
  </div>
  */
  {
    "id": "zNBftewU",
    "block": "[[[10,0],[14,0,\"youtubes\"],[12],[1,\"\\n  \"],[10,1],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\"],[\"before-youtubes-search\",\"div\",[28,[37,3],null,[[\"selectCategory\",\"selectTag\",\"tags\",\"categories\"],[[28,[37,4],[[30,0],\"updateSelectedCategories\"],null],[28,[37,4],[[30,0],\"updateSelectedTags\"],null],[30,0,[\"indexController\",\"tags\"]],[30,0,[\"indexController\",\"categories\"]]]]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,5],null,[[\"@searchTerm\",\"@onSearch\"],[[28,[37,6],[[30,0,[\"indexController\",\"searchTerm\"]]],null],[28,[37,4],[[30,0],\"performSearch\"],null]]],null],[1,\"\\n\\n  \"],[46,[28,[37,8],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"div\",\"span\",\"plugin-outlet\",\"hash\",\"action\",\"youtubes-search\",\"readonly\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/gorisa-youtubes/discourse/templates/youtubes.hbs",
    "isStrictMode": false
  });
});