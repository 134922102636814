define("discourse/plugins/gorisa-youtubes/discourse/initializers/setup-youtubes", ["exports", "discourse/lib/plugin-api", "I18n", "discourse/plugins/gorisa-youtubes/lib/get-youtubes"], function (_exports, _pluginApi, _I18n, _getYoutubes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initialize(api, container) {
    const siteSettings = container.lookup("service:site-settings");
    const youtubesPath = (0, _getYoutubes.getYoutubes)();
    api.addKeyboardShortcut("g e", "", {
      path: "/" + youtubesPath
    });
    if (siteSettings.youtubes_add_to_top_menu) {
      api.addNavigationBarItem({
        name: "youtubes",
        displayName: _I18n.default.t("youtubes.title"),
        href: "/" + youtubesPath
      });
    }
  }
  var _default = _exports.default = {
    name: "setup-youtubes",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.youtubes_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8", api => initialize(api, container));
      if (siteSettings.youtubes_add_search_menu_tip) {
        (0, _pluginApi.withPluginApi)("0.12.6", api => {
          api.addSearchSuggestion("in:youtubes");
          const tip = {
            label: "in:youtubes",
            description: _I18n.default.t("youtubes.search.tip_description"),
            clickable: true,
            searchTopics: true
          };
          api.addQuickSearchRandomTip(tip);
        });
      }
      (0, _pluginApi.withPluginApi)("1.2.0", api => {
        api.addCommunitySectionLink({
          name: "youtubes",
          route: "youtubes.index",
          title: _I18n.default.t("sidebar.youtubes_link_title"),
          text: _I18n.default.t("sidebar.youtubes_link_text")
        });
      });
    }
  };
});