define("discourse/plugins/gorisa-youtubes/discourse/controllers/youtubes", ["exports", "@ember/controller", "@ember/object"], function (_exports, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class YoutubesController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "indexController", [(0, _controller.inject)("youtubes.index")]))();
    #indexController = (() => (dt7948.i(this, "indexController"), void 0))();
    updateSelectedCategories(category) {
      this.indexController.send("updateSelectedCategories", category);
      return false;
    }
    static #_2 = (() => dt7948.n(this.prototype, "updateSelectedCategories", [_object.action]))();
    updateSelectedTags(tag) {
      this.indexController.send("updateSelectedTags", tag);
      return false;
    }
    static #_3 = (() => dt7948.n(this.prototype, "updateSelectedTags", [_object.action]))();
    performSearch(term) {
      this.indexController.send("performSearch", term);
      return false;
    }
    static #_4 = (() => dt7948.n(this.prototype, "performSearch", [_object.action]))();
  }
  _exports.default = YoutubesController;
});