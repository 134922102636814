define("discourse/plugins/gorisa-youtubes/discourse/components/youtubes-category", ["exports", "@ember/component", "@ember-decorators/component", "discourse-common/utils/decorators"], function (_exports, _component, _component2, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const YoutubesCategory = dt7948.c(class YoutubesCategory extends _component.default {
    categoryName(category) {
      return this.site.categories.findBy("id", category.id).name;
    }
    static #_ = (() => dt7948.n(this.prototype, "categoryName", [(0, _decorators.default)("category")]))();
  }, [(0, _component2.tagName)("")]);
  var _default = _exports.default = YoutubesCategory;
});