define("discourse/plugins/gorisa-youtubes/lib/get-youtubes", ["exports", "discourse/models/site"], function (_exports, _site) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getYoutubes = getYoutubes;
  function getYoutubes() {
    return _site.default.currentProp("youtubes_path") || "youtubes";
  }
});