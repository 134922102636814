define("discourse/plugins/gorisa-youtubes/discourse/components/youtubes-topic-list", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component", "discourse-common/utils/decorators", "discourse/plugins/gorisa-youtubes/lib/get-youtubes"], function (_exports, _component, _object, _component2, _decorators, _getYoutubes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const YoutubesTopicList = dt7948.c(class YoutubesTopicList extends _component.default {
    urlPath = (() => (0, _getYoutubes.getYoutubes)())();
    sortTitle(order) {
      return order === "title";
    }
    static #_ = (() => dt7948.n(this.prototype, "sortTitle", [(0, _decorators.default)("order")]))();
    sortActivity(order) {
      return order === "activity";
    }
    static #_2 = (() => dt7948.n(this.prototype, "sortActivity", [(0, _decorators.default)("order")]))();
    sortListActivity() {
      this.sortBy("activity");
      return false;
    }
    static #_3 = (() => dt7948.n(this.prototype, "sortListActivity", [_object.action]))();
    sortListTitle() {
      this.sortBy("title");
      return false;
    }
    static #_4 = (() => dt7948.n(this.prototype, "sortListTitle", [_object.action]))();
  }, [(0, _component2.classNames)("youtubes-topic-list")]);
  var _default = _exports.default = YoutubesTopicList;
});