define("discourse/plugins/gorisa-youtubes/discourse/components/youtubes-topic", ["exports", "@ember/component", "@ember/object/computed", "@ember-decorators/component", "discourse-common/lib/debounce", "discourse-common/utils/decorators"], function (_exports, _component, _computed, _component2, _debounce, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const YoutubesTopic = dt7948.c(class YoutubesTopic extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "originalPostContent", [(0, _computed.reads)("post.cooked")]))();
    #originalPostContent = (() => (dt7948.i(this, "originalPostContent"), void 0))();
    post(stream) {
      return this.store.createRecord("post", stream?.posts.firstObject);
    }
    static #_2 = (() => dt7948.n(this.prototype, "post", [(0, _decorators.default)("topic.post_stream")]))();
    model() {
      const post = this.post;
      if (!post.topic) {
        post.set("topic", this.topic);
      }
      return post;
    }
    static #_3 = (() => dt7948.n(this.prototype, "model", [(0, _decorators.default)("post", "topic")]))();
    _emitScrollEvent() {
      this.appEvents.trigger("youtubes-topic:current-post-scrolled");
    }
    static #_4 = (() => dt7948.n(this.prototype, "_emitScrollEvent", [_decorators.bind]))();
    debounceScrollEvent() {
      (0, _debounce.default)(this, this._emitScrollEvent, 200);
    }
    static #_5 = (() => dt7948.n(this.prototype, "debounceScrollEvent", [_decorators.bind]))();
    didInsertElement() {
      super.didInsertElement(...arguments);
      document.body.classList.add("archetype-youtubes-topic");
      document.addEventListener("scroll", this.debounceScrollEvent);
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      document.body.classList.remove("archetype-youtubes-topic");
      document.removeEventListener("scroll", this.debounceScrollEvent);
    }
  }, [(0, _component2.classNames)("youtubes-topic")]);
  var _default = _exports.default = YoutubesTopic;
});