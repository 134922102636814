define("discourse/plugins/gorisa-youtubes/discourse/components/youtubes-search", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component"], function (_exports, _component, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const YoutubesSearch = dt7948.c(class YoutubesSearch extends _component.default {
    onKeyDown(event) {
      if (event.key === "Enter") {
        this.set("searchTerm", event.target.value);
        this.onSearch(event.target.value);
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "onKeyDown", [_object.action]))();
    clearSearch() {
      this.set("searchTerm", "");
      this.onSearch("");
    }
    static #_2 = (() => dt7948.n(this.prototype, "clearSearch", [_object.action]))();
  }, [(0, _component2.classNames)("youtubes-search")]);
  var _default = _exports.default = YoutubesSearch;
});