define("discourse/plugins/gorisa-youtubes/discourse/templates/components/youtubes-topic-list-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{this.topicListItemContents}}
  */
  {
    "id": "GbVfFQPI",
    "block": "[[[1,[30,0,[\"topicListItemContents\"]]]],[],false,[]]",
    "moduleName": "discourse/plugins/gorisa-youtubes/discourse/templates/components/youtubes-topic-list-item.hbs",
    "isStrictMode": false
  });
});