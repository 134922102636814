define("discourse/plugins/gorisa-youtubes/discourse/youtubes-route-map", ["exports", "discourse/plugins/gorisa-youtubes/lib/get-youtubes"], function (_exports, _getYoutubes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    const youtubesPath = (0, _getYoutubes.getYoutubes)();
    this.route("youtubes", {
      path: "/" + youtubesPath
    }, function () {
      this.route("index", {
        path: "/"
      });
    });
  }
});